/* ****************** COLOUR ****************** */
// matches color in new icon
$brand-color: #051F54;
$brand-highlight: #051F54;
$brand-highlight-light: #E9F2F9;
// THIS IS THE CURRENT BRAND RED
$brand-highlight-red: #051F54;
// EC1C24 ==  rgba(236, 28, 36, 1)
$brand-primary: $brand-color;
$brand-primary-dark: #051F54;
$brand-primary-light: #E9F2F9;
/* use opaque color because the style may be applied both to a table cell and to the content */
$table-draft-colour: rgba(255, 210, 0, 1);
$nav-draft-colour: rgba(255, 22, 0, 1);

$app-tag-colour: #8DD3F0;
$app-tag-button-colour: $brand-primary-dark;
$app-tag-button-hover-colour: $brand-highlight-red;

$brand-primary-hover: $brand-highlight-light;
$brand-selected: $brand-highlight-light;

$button-border-radius: 3px;

$ehr-brand-option1: #051F54;
$ehr-brand-option1: #051F54;
// THIS IS THE ACTIVE BRAND COLOUR
$ehr-brand-option2: #051F54;
$ehr-hover-brand-option1: #083183;
$ehr-hover-brand-option2: #535C91;

$colour-brand-ehr:  $ehr-brand-option1;
$colour-brand-ehr-hover: $ehr-hover-brand-option1;

$colour-brand-lis: $ehr-brand-option1; // #1C9B75; // #23BE8F; //#249141;
$colour-brand-lis-hover: $ehr-brand-option1; // #23BE8F;

$white: #FFF;
$grey03: hsl(0, 0%, 97%);
$grey05: hsl(0, 0%, 95%);
$grey10: hsl(0, 0%, 90%);
$grey15: hsl(0, 0%, 85%);
$grey20: hsl(0, 0%, 80%);
$grey22: hsl(0, 0%, 78%);
$grey25: hsl(0, 0%, 75%);
$grey30: hsl(0, 0%, 70%);
$grey40: hsl(0, 0%, 60%);
$grey50: hsl(0, 0%, 50%);
$grey60: hsl(0, 0%, 40%);
$grey70: hsl(0, 0%, 30%);
$grey80: hsl(0, 0%, 20%);
$grey90: hsl(0, 0%, 10%);
$brand-red60: #E9F2F9;
$brand-red80: hsl(358, 85%, 90%);
$brand-red90: hsl(358, 85%, 95%);
$brand-blue60: #E9F2F9;
$black: black;
$greyWarn: $grey10;
$red: $brand-highlight-red;
$green-light: #7ED321;
$green: #7EA1C4;
$green1: rgb(38, 194, 11);
$green2: $green;
$blue: $brand-highlight;

$table-row-even: rgb(235,235,235);
$table-row-odd: rgb(250,250,250);
$table-header-colour: $grey20;
$table-border-colour: $grey40;
$table-cell-border-colour: rgba(39,56,144,0.25);
$table-header-row-colour: rgba(28,167,225, 0.05);

$activity-header-colour: $brand-blue60;
$lobj-header-colour: $brand-blue60;
$seed-header-colour: $brand-red60;

$button-active-color: $ehr-brand-option2;
/* ****************** TYPOGRAPHY ****************** */
$bodyFontFamily: 'Source Sans 3', sans-serif;
$bodyFontSize: 16px;

// Code 39 is a simple barcode font. Start and end with an '*'
@font-face {
	font-family: 'code39';
	src: url('~/public/assets/fonts/Libre_Barcode_39/LibreBarcode39-Regular.ttf');
}
$fontFamilyCode39: 'code39';
.code39 {
  font-family: $fontFamilyCode39;
  font-size: 46px;
}
/*
 TO DO implement code128 font because it is more compact. code 128 requires a checksum.
 */

  /* ****************** NAVIGATION ****************** */

$toolbar-background-color: $brand-primary-dark;
$toolbar-color: $grey10;

$nav-background-medium: $black;
$nav-active: #E9F2F9; // $brand-highlight;
$nav-level1: $grey80;
$nav-level2: $grey70;
$nav-level3: $grey60;
$nav-color-level1: $white;
$nav-color-level2: $white;
$nav-color-level3: $white;

/* ****************** MESSAGING ****************** */
$info: $blue;
$success: $green;
$error: $red;
$system-message-text: $white;
$context-banner-background-color: $brand-primary-light;
$context-banner-color: $grey80;
$dialog-header-background: $white;
$dialog-header-content-color: $grey70;
$dialog-wrapper-background-color: $white;
$dialog-wrapper-color: $grey40;

$border-width: 5px;
$border1: blue;
$border2: red;
$border3: #1CA7E1;
$border4: green;
$border5: purple;
$border6: mediumvioletred;
$border7: springgreen;

$o-colours: (
  a: blue,
  b: red,
  c: darkorange,
  d: green
);

@mixin debugColor($index: a) {
  border: 1px solid map-get($o-colours, $index);
}

/* ****************** DIMENSIONS ****************** */

$dialog-width-threshold: 700px;
$main-width-threshold1: 500px;
$main-width-threshold2: 650px;
$main-width-threshold3: 900px;
$main-width-threshold4: 1000px;

$buttonRowLeftMargin: 5px;

/*

 */
$ehr-layout-padding-left: 5px;
$ehr-layout-padding-left_level2: 20px;
$ehr-layout-padding-left_level3: 30px;

/* ****************** WRAPPER ****************** */

$o-wrapper-sizes: (
  page:   1400px,
  content:  1024px,
  wide:   682px,
  narrow: 342px,
);

@mixin wrapper($size: page) {
  //margin-right: auto;
  //margin-left: auto;
  padding-right: 0;
  padding-left: 0;
  max-width: map-get($o-wrapper-sizes, $size);
}

.mr5 {
  margin-right: 5px
}
