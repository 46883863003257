
@import '../../scss/definitions';

/* 主题色 */
$primary-color: #051F54;
$secondary-color: #6c757d;
$background-color: #ffffff;
$text-color: #333333;
$border-color: #e0e0e0;

/* 导航菜单容器 */
#nav-menu {
  background-color: $background-color;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px;
  max-width: 250px;
  position: relative;

  @media (max-width: 900px) {
    max-width: 100%;
  }
}

/* 导航链接 */
.router-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  text-decoration: none;
  color: $text-color;
  border-radius: 6px;
  transition: background-color 0.3s, color 0.3s;
  border-left: 3px solid transparent;

  &:hover {
    background-color: #E9F2F9;
    //border-left-color: $primary-color;
  }

  &.active-link {
    background-color: #E9F2F9;
    //border-left-color: $primary-color;
  }

  .fa {
    margin-right: 10px;
    color: $secondary-color;
  }

  span {
    flex: 1;
  }
}

/* 不同级别的菜单项缩进 */
/*.router-item.level1 {
  margin-left: 0;
}

.router-item.level2 {
  margin-left: 20px;
}

.router-item.level3 {
  margin-left: 40px;
}*/

/* 开发者模式切换 */
.mode-toggle {
  margin: 10px 0 10px 20px;
  display: flex;
  align-items: center;

  input[type="checkbox"] {
    margin-right: 10px;
  }

  label {
    font-weight: bold;
    color: $text-color;
  }
}

/* 解释文本 */
.explain-text {
  font-size: 0.9em;
  color: $secondary-color;
  margin-left: 20px;
}

/* 分割线 */
hr {
  border: none;
  border-top: 1px solid $border-color;
  margin: 20px 0;
}

/* 响应式设计 */
@media (max-width: 768px) {
  #nav-menu {
    padding: 10px;
    margin: 10px;
  }

  .router-item {
    //flex-direction: column;
    //align-items: flex-start;
    padding: 10px 0;

    .fa {
      margin-right: 10px;
      margin-bottom: 0px;
    }

    span {
      flex: 1;
    }
  }

  /* 保持响应式缩进 */
  /*.router-item.level2 {
    margin-left: 10px;
  }

  .router-item.level3 {
    margin-left: 20px;
  }*/
}
